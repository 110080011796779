<script>
import store from './store/index.js';
// import { messagePush } from './lib/util.js';
// import base from 'api/base';
export default {
	onLaunch: function() {
		this.isSave = true;
		// messagePush();
	},
	// onError(err){
	// 	try{
	// 	const pages=getCurrentPages(),path= pages[pages.length-1].route;
	// 	console.log(  err.message, err.stack);
	// 	if(process.env.NODE_ENV !== 'development') base.addUserFrontLog({check:false,loading:false,data:{path,message:err.message,stack:err.stack}})
	// 	}catch(err){
	// 		console.log(err)
	// 	}
	// },
	onHide() {
		console.log('hide');
		if (this.isSave) {
			// #ifdef APP-PLUS
			// app从列表去掉会触发hide,返回不会触发
			uni.setStorageSync(this.STORE_NAME, JSON.stringify(store.state));
			// #endif
			// #ifdef H5
			uni.setStorageSync(this.STORE_NAME, JSON.stringify(store.state));
			// #endif
		}
	}
};
</script>

<style lang="less">
/*每个页面公共css */
@import '@/common/style/layout.less';
@import '@/common/style/atom.less';
@import '@/common/style/reset.less';
@import '@/style/common.less';
@import '@/style/util.less';
@import '@/style/iconfont.css';
page {
	background-color: #f8f8f8;
	font-size: @font-size-medium-x;
}
.status_bar {
	height: var(--status-bar-height);
	width: 100%;
}
</style>
