import Loading from './index.vue';
import store from 'store';
import { createApp } from 'vue'
export default function(app) {
	let instance;
	app.directive('loading', {
		mounted: function(el, binding) {
			console.log(el)
			// store.commit('setLoading', !binding.value)
			instance = createApp(Loading,{
				propsData: {
					show: binding.value,
					mode: 'flower',
					size: 120,
					loadingStyle: {
						position: 'absolute',
						top: '50%',
						left: '50%',
						margin: '30px -30px 30px -30px'
					}
				}
			}).mount('#app1');
			console.log(instance,el.appendChild,el.style)
			// el.style.position = 'relative';
			// el.appendChild(instance.$el);
		},
		update(el, binding) {
			// store.commit('setLoading', !binding.value)
			if (instance) {
				if (binding.value) instance.$el.style.display = 'block';
				else instance.$el.style.display = 'none';
			}
		},
		componentUpdated(el, binding){
			// store.commit('setLoading', !binding.value)
			if (instance) {
				if (binding.value) instance.$el.style.display = 'block';
				else instance.$el.style.display = 'none';
			}
		},
		unmounted(el) {
			if (instance) {
				instance.$destroy();
				if(el.contains(instance.$el)) el.removeChild(instance.$el);
				else if(instance.$el) instance.$el.remove();
				instance = null;
			}
			// store.commit('setLoading', true)
		}
	});
}
