export default {
  // baseUrl: "http://hrapi.tyjfwy.com/public/index.php/wx",
  // txhgUrl: "http://txhgapi.tyjfwy.com/public/index.php/wx",
  // #ifdef H5
  // baseUrl: "http://hrapptest.tyjfwy.com",
  // token: "12345678",
  baseUrl: "http://hrapp.tyjfwy.com",
  token:'7def1_3488f_f_e3',
  // #endif
  // #ifdef APP-PLUS
  baseUrl: "https://hrapp.tyjfwy.com",
  token: "",
  // #endif
  domain: "https://hrapi.tyjfwy.com",
  imgURL:'http://hrapi.tyjfwy.com',
  currentModule:'txhg',
  appid: "wxa5d32200e0692c5f",
  backUrl: "http://hrapi.tyjfwy.com", //后台地址，用于展示图片
};