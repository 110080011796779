import md5 from "@/plugins/md5";
import purefn from "@widget/purefn";
import {encryptKey} from '@/config';
const getType=purefn.typeUtil('getType');
// 加密参数 所有接口都进行加密 过滤空字符串 只有简单类型字段加入加密
function encrypt(param) {
	if(param.sign) delete param.sign;
    param=new purefn.ObjUtil(param).clearEmpty().value;
    // if(!param||(getType(param)==='array'&&param.length<=0)||(getType(param)==='object')&&Object.keys(param).length<=0) return param;
    
    const nonceStr = purefn.StrUtil.radomString(5)+Date.now();
    // 1.拼接公共参数
    param = Object.assign({}, param, {nonceStr});
    // 2.去空 按字典排序参数 格式化参数 添加key md5加密 转换大写 得到sign
    // console.log(param,new purefn.ObjUtil(param).clearEmpty().sort().queryString())
    param.sign = md5(purefn.ObjUtil.queryString(new purefn.ObjUtil(param).filter(item=>typeof item!=='object').clearEmpty().sort().value)+ '&key='+encryptKey).toUpperCase();
    return param;
}

export default function(http){
	http.interceptor.request.use(config=>{
		config.data=encrypt(config.data||{});
		return Promise.resolve(config);
	})
};