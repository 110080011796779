import ObjUtl from './ObjUtil';
import eventBus from '@/eventBus';
import StrUtil from './StrUtil.js';
import store from '@/store';
// import {
// 	getTabBar
// } from '@config';

// remeber parameter
const REMPARAM = [ 'disabled']; //保留参数
function remParamF(query) {
	if (query) {
		const pages = getCurrentPages(),
			curPage = pages[pages.length - 1] || null;
			if(!curPage) return
		const prevParam = StrUtil.queryString(curPage.route, {
			fullMatch: true
		});
		Object.keys(prevParam).forEach(key => {
			if (REMPARAM.includes(key) && !query.hasOwnProperty(key)) query[key] = prevParam[key];
		});
	}
}
function canPage(url){
	return new Promise(resolve=>{
		uni.preloadPage({url
		}).then(res=>{
			resolve(true)
		}).catch(err=>{
			resolve(false)
		});
	})
}
/**
 *
 * @param mode
 * @param url
 * @param outerParam
 * @param level
 * @param refresh 刷新前一页
 * @returns {Promise<unknown>}
 */
async function jump(parameter = {}) {
	const currentModule=store.state.currentModule;
	let {
	mode = 'back',
	path:url = '',
	query: outerParam = {},
	param = {}, //当param过于复杂时应使用globalData传递参数
	level = 1,
	refresh = false,
	remParam = false
}=(typeof parameter==='string'?{url:parameter}:parameter);
	const pages = getCurrentPages();
	//防止重新跳转同一页面
	if (url && pages.length > 0 &&pages[pages.length - 1].$page&& pages[pages.length - 1].$page.route.includes(url)) return false;
	// 处理页面refresh
	if (refresh) {
		const prevPage = pages[pages.length - 2] || null;
		// #ifdef APP-PLUS
		if (prevPage && prevPage.$vm.init) prevPage.$vm.init(prevPage.$vm.$query); //app端写法
		if (prevPage && prevPage.$vm.$refs.recycle) prevPage.$vm.$refs.recycle.forceFetch();
		// #endif
		// #ifdef H5
		if (prevPage && prevPage.init) prevPage.init(prevPage.$query); //调用列表页面方法
		if (prevPage && prevPage.$refs.recycle) prevPage.$refs.recycle.forceFetch();
		// #endif
	}
	// url处理
	console.log(url)
	if (url.startsWith('http')) {
		if(!['assign','replace'].includes(mode)) mode = 'assign';
	} else if(url) {
		if (!mode||mode==='back') mode = 'to';
		// 判断tab跳转方式
		let [page, query = ''] = url.split('?');
		// for (let tabName of getTabBar().list) {
		// 	if (new RegExp(`\\b${tabName}\\b`).test(page)) {
		// 		mode = 'tab';
		// 		continue;
		// 	}
		// }
		if (remParam) remParamF(outerParam);
		// url参数拼接
		const param = ObjUtl.queryString(outerParam);
		// pages/home/index
		/**
		 * pages/noticeList/index => navigateTo:fail page `/pages/me/pages/noticeList/index` is not foun
		 */
		if (!page.startsWith('/')) {
			const modulePath=currentModule?'/'+currentModule:'';
			if (page.startsWith('pages')) page = '/' + page;
			else page = `/pages${modulePath}/` + page;
		}
		if (!page.endsWith('index')) {
			const canIn=await canPage(page);
			if(!canIn) page += '/index';
		}
		if (url.includes('?')) {
			// url携带参数,拼接参数
			url = `${page}?${query}${param?'&'+param:param}`;
		} else {
			// url未携带参数，拼接参数
			url = param ? `${page}?${param}` :
				page;
		}
	}
	console.log('url jump', url,mode)
	// 手动触发
	eventBus.emit('afterRouteLeave');
	return new Promise((resolve, reject) => {
		switch (mode) {
			case 'to':
				uni.navigateTo({
					url: url,
					animationType: 'slide-in-right',
					success: resolve,
					fail: reject
				});
				break;
			case 'redirect':
				uni.redirectTo({
					url: url,
					animationType: 'pop-in',
					success: resolve,
					fail: reject
				});
				break;
			case 'reLaunch':
				uni.reLaunch({
					url: url,
					success: resolve,
					fail: reject
				});
				break;
			case 'tab':
				uni.switchTab({
					url: url,
					success: resolve,
					fail: reject
				});
				break;
			case 'none':
				break;
			case 'back':
			console.log(level)
				uni.navigateBack({
					delta: level,
					animationType: 'slide-out-right',
					// animationDuration: 200,
					success: resolve,
					fail: reject
				});
				break;
				// 跳转
			case 'assign':
				eventBus.emit('pageleave');
				location.href = url;
				break;
			case 'replace':
				eventBus.emit('pageleave');
				location.replace(url);
				break;
			default:
			
				uni.navigateBack({
					delta: level,
					animationType: 'slide-out-right',
					success: resolve,
					fail: reject
				});
				break;
		}
	});

}

export default jump;
