import store from '@/store';
// import {setReaded} from '@utils/util.js';
import uface from '@widget/uface';
import eventBus from '@/eventBus.js';
import wx from '@utils/wx.js';
import uconsole from '@utils/uconsole.js';
import {
	isInExclude
} from '@utils/auth.js';
// import VConsole from 'vconsole';

// if (process.env.APP_ENV === 'devPro') {const vConsole = new VConsole();}
// if (process.env.APP_ENV === 'devPro' && /Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) uconsole();
// 去掉打印
if (process.env.APP_ENV === 'pro') console.log = () => {};
/**
 * app登录，跳转auth
 * wx登录，使用wx.authorize
 * 由于多模块，当前模块未知，必须先请求login获取当前模块
 */
export default function(app) {
	// // #ifdef H5
	// // 模板消息打开
	// if(location.search&&location.search.includes('templateid')){
	// 	setReaded(purefn.StrUtil.queryString(location.search).templateid);
	// }
	// // #endif
	//刷新保存状态
	let STORE_NAME;
	// #ifdef APP-PLUS
	STORE_NAME = 'hr_manage';
	// #endif
	// #ifdef H5
	STORE_NAME = location.host + '_store';
	// #endif
	app.config.globalProperties.STORE_NAME = STORE_NAME;
	// #ifdef H5
	if (process.env.NODE_ENV === 'development') uni.removeStorageSync(STORE_NAME);
	// #endif
	console.log(uni.getStorageSync(STORE_NAME))
	if (uni.getStorageSync(STORE_NAME)) {
		store.replaceState(
			JSON.parse(uni.getStorageSync(STORE_NAME))
		);
		// uni.removeStorageSync(STORE_NAME)
	}
	// 防止上一次的缓存影响导致不能跳转登录
	store.commit('setCache', {
		isAuthHandle: false
	})
	// #ifdef H5

	//监听，在页面刷新时将vuex里的信息保存到Storage里
	// window.addEventListener("beforeunload", () => {  //苹果真机不可用
	eventBus.on("pageleave", () => {
		console.log(store.state)
		uni.setStorageSync(STORE_NAME, JSON.stringify(store.state));
	});

	window.addEventListener('pagehide',
		function() {
			uni.setStorageSync(STORE_NAME, JSON.stringify(store.state));
		});
	// 判断登录
	console.log(store.state.token)
		//跳转授权之前缓存入口地址 用于登录后再次进入 每次进入都记录，防止因为缓存问题跳到上次的页面路径
		let path = location.pathname+location.search;
		if (!path.includes('pages')) path = 'home';
		if (!path.includes('auth')) store.commit('setEnteryPath', path);
		store.commit('setEnteryFullPath', location.href);
	// if (!store.state.token && !isInExclude(path)) {
		if (!store.state.token) {
		store.commit('setCache', {
			isAuthHandle: true
		})
		wx.authorize();
	}
	// #endif
	// #ifdef APP-PLUS
	// uface.jump({
	// 	mode: 'redirect',
	// 	path: 'auth'
	// });
	// #endif
}
