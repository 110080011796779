import ObjUtil from '../ObjUtil';
import adapter from './adapter';
import InterceptorManage from './InterceptorManage';
import eventBus from '@/eventBus.js';
const configDefault = {
    header:{
    'content-type': 'application/json; charset=utf-8'
    }
};
function radomToken(name='radomToken') {
    const str='1234567890abcdefghijklmnopqrstuvwxyz';
        let boundary = name;
        for (let i = 0; i < 20; i++) {
            boundary += str[Math.floor(Math.random() * 36)];
        }
        return boundary;
    }
export default class Request {
    /**
     * conf.baseUrl 域名前缀
     * conf.url 请求路径
     * conf.data 参数
     * conf.method 请求类型
     * @param {*} conf 
     */
    constructor(conf = {}) {
        const config =ObjUtil.merge({}, configDefault, conf);
        this.config=config;
        this.interceptor={
            request:new InterceptorManage(),
            response: new InterceptorManage()
        };
    }
    /**
     * 请求模型 洋葱式
     * 公共request私有request - > - > 请求 - > 公共response->私有response 
	 * 可多次调用use进行拦截
     * @param {*} config 
     */
    middleware(config){
        config=ObjUtil.merge({},this.config,config);
        config.method=config.method.toLowerCase();
		const cancelToken=`http.cancel.${radomToken('cancelToken')}`;
		        config.cancelToken=cancelToken;
        let promise=Promise.resolve(config),
		cancel=eventBus.emit(cancelToken);
        const chain = [adapter, err => Promise.reject(err)],request=[],response=[];//占位保证顺序
        this.interceptor.request.each(e=>{
            request.push(e.resolve, e.reject);
        });
        if(config.interceptor) config.interceptor.request.each(e => {
            request.push(e.resolve, e.reject);
        });
		chain.unshift(...request);
        this.interceptor.response.each(e=>{
            response.push(e.resolve, e.reject);
        });
         if(config.interceptor) config.interceptor.response.each(e => {
            response.push(e.resolve, e.reject);
        });
		chain.push(...response);
        while(chain.length>0){
            promise=promise.then(chain.shift(),chain.shift());
        }
        return promise;
    }
	getInterceptor(){
		return {
		    request:new InterceptorManage(),
		    response: new InterceptorManage()
		};
	}
    get(conf){
        return this.middleware(Object.assign({}, this.config, conf,{
            method: 'GET'
        }));
    }
    post(conf) {
        return this.middleware(Object.assign({}, this.config,conf, {
            method: 'POST'
        }));
    }
    upload(conf){
        return this.middleware(Object.assign({}, this.config,conf, {
            method: 'UPLOAD'
        }))
    }
}