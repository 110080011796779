import LiveVideo from '@/components/LiveVideo'
import {createApp} from 'vue';
const empty = () => {};
import init from '@/core';
import {registerDirective as initComponent} from '@/common/components';
import popup from '@/common/components/popup/index.vue';

function showLiveVideo({
	
} = {}) {
	let wrapNode=document.querySelector('uni-page')||document.body, rootNode=document.createElement('div')
	let success, fail,instance;//可能短时间内触发多次方法
	const then = (successCB = empty, failCB = empty) => {
		success = successCB;
		fail = failCB;
	}

	function onClose() {
		setTimeout(() => {
			if (instance) {
				instance.unmount();
				wrapNode.removeChild(rootNode);
				instance = null;
				rootNode=null;
				console.log('definemodal destroy')
			}
		}, Number(popup.props.duration.default) + 10)
	}
	setTimeout(() => {
		instance = createApp(LiveVideo,{
			success,
			fail,
			onClose,
		});
		instance.mount(rootNode);
		console.log(instance,rootNode)
		init(instance)
		initComponent(instance)
		wrapNode.appendChild(rootNode);
	}, 0);
	return {
		then
	}
}
export default showLiveVideo;
