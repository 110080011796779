const list = {

	getUserInfo: 'User/getUserInfo', //获取登录人用户详情




    getUserDetail: 'LawUser/getUserDetail', //获取登录人个人信息
    changUserPassword: 'LawUser/changUserPassword', //修改密码

	
};

export default list;