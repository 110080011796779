import App from './App'

import init from './core';
import api from '@api';
import login from '@utils/login';

import {
	createSSRApp
} from 'vue'
export function createApp() {
	const app = createSSRApp(App)
	app.config.errorHandler = function(error, vm, info) {
		// 处理错误
		// `info` 是 Vue 特定的错误信息，比如错误所在的生命周期钩子
		//error对象上的大多数属性都是non-enumerable(https://developer.mozilla.org/en-US/docs/Web/JavaScript/Enumerability_and_ownership_of_properties)，因此您需要执行以下操作：
		console.error(Object.assign({}, 
      error,
      {      // Explicitly pull Error's non-enumerable properties
        name: error.name, 
        message: error.message, 
        stack: error.stack
      }
    ), vm.$route ? vm.$route.fullPath : '', info)
		// if (err.toString() && err.toString().includes('No match for')) return false;
		// if (process.env.NODE_ENV !== 'development') $api.base.addUserFrontLog({
		// 	canResponse: false,
		// 	data: {
		// 		err,
		// 		path: vm.$route ? vm.$route.fullPath : '',
		// 		lifecycle: info
		// 	}
		// })
	}
	login(app);
	init(app);
	return {
		app
	}
}
