const list = {
    http:'',
	csPic: 'Base/csPic', //新增
    getSignPackage: 'Base/getSignPackage',  //获取微信签名有关
    getIndexModule:'Index/getIndexModule', //获取首页模块
    getUserDepMsg:'Index/getUserDepMsg', //获取个人信息
    getUserByDepid: 'Index/getUserByDepid', //根据t_user_dep主键获取部门用户信息
    addUserViewLog:'Index/addUserViewLog', //记录前台访问页面日志
	addUserFrontLog:'Base/addUserFrontLog', //记录前台错误日志
    getMobileUserDepMsg: 'Index/getMobileUserDepMsg', //获取个人信息 无需depid
	
	getNewsInfo: 'News/getNewsInfo', //获取信息详情
	getSwiperContent: 'Index/getSwiperContent', //获取轮播图内容
};

export default list;