<template>
	<view class="upload">
		<u-choose-image class="item add" @files="choose"><text class="iconfont iconai-up-img"></text></u-choose-image>
		<view class="item" v-for="(item, index) in img">
			<Img width="100%" height="100%" :src="item" :preview="true" />
			<text class="iconfont del-icon iconshanchu11" @click.stop="img.splice(index, 1)"></text>
		</view>
	</view>
</template>

<script>
export default {
	name: 'multi-file',
	props:{
		// 最大上传数量
				maxCount: {
					type: [String, Number],
					default: 9
				},
		// 文件大小限制，单位为byte
				maxSize: {
					type: [String, Number],
					default: Number.MAX_VALUE
				},
		// 允许上传的图片后缀
				limitType:{
					type: Array,
					default() {
						// 支付宝小程序真机选择图片的后缀为"image"
						// https://opendocs.alipay.com/mini/api/media-image
						return ['png', 'jpg', 'jpeg', 'webp', 'gif', 'image'];
					}
				},
	},
	data() {
		return {
			img: []
		};
	},
	methods: {
		// 判断文件后缀是否允许
		checkFileExt(file) {
			// 检查是否在允许的后缀中
			let noArrowExt = false;
			// 获取后缀名
			let fileExt = '';
			const reg = /.+\./;
			// 如果是H5，需要从name中判断
			// #ifdef H5
			fileExt = file.name.replace(reg, "").toLowerCase();
			// #endif
			// 非H5，需要从path中读取后缀
			// #ifndef H5
			fileExt = file.path.replace(reg, "").toLowerCase();
			// #endif
			// 使用数组的some方法，只要符合limitType中的一个，就返回true
			noArrowExt = this.limitType.some(ext => {
				// 转为小写
				return ext.toLowerCase() === fileExt;
			})
			if(!noArrowExt) this.$showToast({title:`不允许选择${fileExt}格式的文件`,icon:'warning'});
			return noArrowExt;
		},
		choose(e) {
			let imgs=[...this.img];
			e.forEach((file,index)=>{
				// 检查文件后缀是否允许，如果不在this.limitType内，就会返回false
				if(!this.checkFileExt(file)) return ;
				if(file.size>this.maxSize){
					this.$showToast({title:'超出允许的文件大小',icon:'warning'});
					return;
				}
				if(imgs.length>this.maxCount){
					this.$showToast({title:'超出最大允许的文件个数',icon:'warning'});
					return
				}
				imgs.push(file.path);
			})
			this.img=imgs;
		}
	}
};
</script>

<style lang="less" scoped>
.upload {
	display: flex;
	flex-wrap: wrap;
	padding: 0 10px;
	.add {
		border: 1rpx solid @border-color;
		.iconfont {
			font-size: 80rpx;
			color: grey;
		}
	}
	.item {
		width: 66px;
		height: 66px;
		background: #fff;
		position: relative;
		border-radius: 4px;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-right: 6px;
		margin-bottom: 6px;
		.del-icon {
			position: absolute;
			top: -6px;
			right: -6px;
			color: #4c4c4c;
		}
	}
}
</style>
