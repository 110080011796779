// dayjs https://segmentfault.com/a/1190000015560102
// import * as C from './constant';
import * as C from './constant.js';
import U from './util';
class Day {
  constructor(date, formatStr) {
    this.formatStr = formatStr;
    this.$date = this.parseDate(date);
    if (date !== undefined && !this.isValid())
      throw new Error("date is inValid!!!");
    this.initDate();
  }
  get value() {
    return this.formatting(this.formatStr);
  }
  clone() {
    return day(this.$date);
  }
  parseDate(date) {
    if (date === null) return new Date(NAN);
    else if (date === undefined) return new Date();
    else if (date instanceof Date) return new Date(date);
    else if (date && date === String) {
      const matched = date.match(C.REGEX_PARSE);
      return new Date(
        matched[1],
        matched[2] - 1 || 0,
        matched[3] || 1,
        matched[4] || 0,
        matched[5] || 0,
        matched[6] || 0,
        (matched[7] || "0").substring(0, 3)
      );
    }
    return new Date(date);
  }
  initDate() {
    const d = this.$date;
    this.$year = d.getFullYear();
    this.$month = d.getMonth() + 1;
    this.$day = d.getDate();
    this.$week = d.getDay() === 0 ? 7 : d.getDay();
    this.$hour = d.getHours();
    this.$minute = d.getMinutes();
    this.$second = d.getSeconds();
    this.$millisecond = d.getMilliseconds();
  }
  /**
     * 所有可用单位列表
    单位	缩写	描述
    date	D	日期
    day	    d	星期(星期日0，星期六6)
    month	M	月份(0-11)
    year	y	年
    hour	h	小时
    minute	m	分钟
    second	s	秒
    millisecond	ms	毫秒
    */
  $set(unit, val) {
    console.log(unit, val);
    const map = {
        year: "FullYear",
        month: "Month",
        day: "Date",
        hour: "Hours",
        minute: "Minutes",
        second: "Seconds",
        millisecond: "Time",
      },
      name = map[unit] || "day";
    this.$date["set" + name](val);
    return this;
  }
  /**
     * 所有可用解析标记的列表

    标识	示例	描述
    YY	18	年，两位数
    YYYY	2018	年，四位数
    M	1-12	月，从1开始
    MM	01-12	月，两位数字
    MMM	Jan-Dec	月，英文缩写
    D	1-31	日
    DD	01-31	日，两位数
    ld 1-31 本月最后一天
    LD 01-31 本月最后一天
    H	0-23	24小时
    HH	00-23	24小时，两位数
    h	1-12	12小时
    hh	01-12	12小时，两位数
    m	0-59	分钟
    mm	00-59	分钟，两位数
    s	0-59	秒
    ss	00-59	秒，两位数
    S	0-9	毫秒（百），一位数
    SS	00-99	毫秒（十），两位数
    SSS	000-999	毫秒，三位数
    Z	-05:00	UTC偏移
    ZZ	-0500	UTC偏移，两位数
    A	AM / PM	上/下午，大写
    a	am / pm	上/下午，小写
    acn 中文 上/下午
    wcn 中文 星期x
    Do	1st... 31st	月份的日期与序号
*/
  formatting(str) {
    const matched = {
      YY: () => String(this.$year).slice(-2),
      YYYY: () => this.$year,
      M: () => this.$month,
      MM: () => U.padStart(this.$month, 2, "0"),
      wcn: () => C.WEEK_MAP[this.$week - 1],
      D: () => this.$day,
      DD: () => U.padStart(this.$day, 2, "0"),
      ld: () => new Date(this.$year, this.$month, 0).getDate(),
      LD: () =>
        U.padStart(new Date(this.$year, this.$month, 0).getDate(), 2, "0"),
      H: () => this.$hour,
      HH: () => U.padStart(this.$hour, 2, "0"),
      h: () => (this.$hour > 12 ? this.$hour - 12 : this.$hour),
      hh: () =>
        U.padStart(this.$hour > 12 ? this.$hour - 12 : this.$hour, 2, "0"),
      m: () => this.$minute,
      mm: () => U.padStart(this.$minute, 2, "0"),
      s: () => this.$second,
      ss: () => U.padStart(this.$second, 2, "0"),
      S: () => String(this.$millisecond).substring(0, 1),
      SS: () => String(this.$millisecond).substring(0, 2),
      SSS: () => String(this.$millisecond).substring(0, 3),
      A: () => (this.$hour <= 12 ? "AM" : "PM"),
      a: () => (this.$hour <= 12 ? "am" : "pm"),
      acn: () => (this.$hour <= 12 ? "上午" : "下午"),
    };
    return str.replace(C.REGEX_FORMAT, (match, $1) => $1 || matched[match]());
  }
  format(str) {
    if (str && C.REGEX_FORMAT.test(str)) this.formatStr = str;
    return this;
  }
  /**
     * 单位不区分大小写，支持复数和缩写形式。
        所有可用单位列表

        单位	缩写	描述
        week	w	周
        day	d	星期(星期日0，星期六6)
        month	M	月份(0-11)
        quarter	Q	依赖QuarterOfYear插件
        year	y	年
        hour	h	小时
        minute	m	分钟
        second	s	秒
        millisecond	ms	毫秒
     */
  add(number, unit = "day") {
    const map = {
      [C.Y](val) {
        this.$set(C.Y, this.$year + val);
      },
      [C.M](val) {
        this.$set(C.M, this.$month + val);
      },
      [C.D](val) {
        this.$set(C.D, this.$day + val);
      },
      [C.W](val) {
        this.$set(C.D, this.$day + val * 7);
      },
      [C.H](val) {
        this.$date = this.valueOf() + val * C.MILLISECONDS_A_HOUR;
      },
      [C.M](val) {
        this.$date = this.valueOf() + val * C.MILLISECONDS_A_MINUTE;
      },
      [C.S](val) {
        this.$date = this.valueOf() + val * MILLISECONDS_A_SECOND;
      },
      [C.MS](val) {
        this.$date = this.valueOf() + val;
      },
    };
    map[unit].call(this, Number(number));
    this.initDate();
    return this;
  }
  /**
     * 单位不区分大小写，支持复数和缩写形式。
    单位	缩写	描述
    week	w	周
    day	d	星期(星期日0，星期六6)
    month	M	月份(0-11)
    quarter	Q	依赖QuarterOfYear插件
    year	y	年
    hour	h	小时
    minute	m	分钟
    second	s	秒
    millisecond	ms	毫秒
    */
  diff(d, unit = "day", float = true) {
    const diffDate = day(d),
      diffMonth =
        (diffDate.year() - this.year()) * 12 + diffDate.month() - this.month(),
      diff = diffDate.valueOf() - this.valueOf(),
      map = {
        [C.Y]() {
          return diffMonth / 12;
        },
        [C.W]() {
          return diff / C.MILLISECONDS_A_WEEK;
        },
        [C.D]() {
          return diff / C.MILLISECONDS_A_DAY;
        },
        [C.H]() {
          return diff / C.MILLISECONDS_A_HOUR;
        },
        [C.M]() {
          return diff / C.MILLISECONDS_A_MINUTE;
        },
        [C.S]() {
          return diff / C.MILLISECONDS_A_SECOND;
        },
        [C.MS]() {
          return diff;
        },
      },
      result = map[unit]();
    return float ? result : U.absFloor(result);
  }
  subtract(number, unit) {
    return this.add(-1 * number, unit);
  }
  isValid() {
    return !(this.$date.toString() === C.INVALID_DATE_STRING);
  }
  valueOf() {
    return this.$date.getTime();
  }
  unix(){
      return Math.floor(this.valueOf()/1000);
  }
  toString(){
      return this.$date.toUTCString();
  }
  toDate() {
    return new Date(this.valueOf());
  }
}
const day=function name(date,formatStr=C.FORMAT_DEFAULT) {
	const map={
		年:'-',
		月:'-',
		日:'',
		时:':',
		分:':',
		秒:':'
	}
	if(date) Object.keys(map).forEach(key=>{
		date=date.replace(key,map[key]);
	})
    return new Day(date,formatStr)
}
day.prototype.unix=timestamp=>day(timestamp*1e3)
// 使用重载的getter和setter，也就是说，调用这些不带参数的方法作为getter，调用带参数的方法作为setter。
const mapFn={
    'y':C.Y,
    'm':C.M,
    'd':C.D,
    'w':C.W,
    'h':C.H,
    's':C.S,
    'ms':C.MS
};
Object.keys(mapFn).forEach(k=>{
    Day.prototype[mapFn[k]] = function(val) {
      if (val) return this.$set(k, val);
      else return this["$" + mapFn[k]];
    };
})
// 扩展插件的方法 接受两个参数，即插件（函数）和插件的选项。
// plugin：插件函数
// option：插件的选项
day.prototype.use = function (plugin, option) {
  // 插件函数接受三个参数
  // 1.插件选项 2.Day 类 3.day 函数
  // 插件的方法都是挂载在 Day 类的原型对象上的（Day.prototype）。
  //直接调用传入的插件（函数），且传入三个参数：传入 day.extend() 方法的 option、Day 类以及 day 函数。最后，返回 day 函数。
  plugin(option, Day, day);
  return day;
};
export default day;