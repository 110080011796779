import typeUtil from '../typeUtil.js';
import eventBus from '@/eventBus.js';
const isJSON=typeUtil('isJSON');
//throw cancel
function throwIfCancel(config) {
    eventBus.on(config.cancelToken,()=>{
    throw 'request cancel'; 
})
}
// 发起请求 转换json
function tranlateJSON(data){
	for(let key in data){
	        if(data.hasOwnProperty(key)&&typeof data[key]==='object'){
	            data[key]=JSON.stringify(data[key]);
	        }
	}
	return data;
}
export default function (config) {
	throwIfCancel(config);
	const fullRequestUrl=config.parseUrl?config.parseUrl(config):config.baseUrl+'/'+config.url;
	config.fullRequestUrl=fullRequestUrl;
    return new Promise((resolve, reject) => {
        const conf = {
            url:fullRequestUrl,
            success(res) {
				throwIfCancel(config);
				// console.log(config.url,res)
                if (res.statusCode>=500){
                    reject({err:'服务器错误,请联系管理员',config,showModal:true});
                    return false;
                }
				if(isJSON(res.data)) res.data=JSON.parse(res.data);
                resolve({res,config})
            },
            fail(err) {
				throwIfCancel(config);
                reject({errLog:err,config,err:'网络错误,请重试',showModal:true});
            },
			complete(){
				throwIfCancel(config);
				if(config.complete) config.complete();
			}
        };
        if(config.translateData==='json') config.data=tranlateJSON(config.data||{});
        if (config.files && config.files.length <= 0) {
            config.method = 'POST';
        }else if(config.files&&config.files.length > 0) config.method = 'upload';
		// 文件上传
        if (config.method === 'upload') {
			config.formData=tranlateJSON(config.data||{});
			config.files=config.files.map(item=>{
				const obj={name:item.name||'file'};
				if(item.path) obj.uri=item.path;
				if(item.file) obj.file=item.file;
				return obj;
			})
			delete config.data;
			if(config.header) delete config.header['content-type'];
            uni.uploadFile(Object.assign({}, config, conf));
        } else {
			// console.log(config.url,config)
            uni.request(Object.assign({}, config, conf));
        }
    });
}