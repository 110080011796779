import proConfig from '@env';
import {
	uface
} from '@widget';
import store from '@/store';

export default {
	/**
	 * 跳转微信授权
	 * @param {*} url  获取code的页面
	 */
	authorize(url = '/pages/auth/index') {
		// 防止微信授权跳转回来后又重新跳到微信授权
		if(location.pathname.includes(url)) return;
		const domain =location.origin;
		// 跳转微信授权
		const redirectUri = (`${domain}${url}`),
			weixinUrl =
			`https://open.weixin.qq.com/connect/oauth2/authorize?appid=${proConfig.appid}&redirect_uri=${redirectUri}&response_type=code&scope=snsapi_userinfo&state=1#wechat_redirect`;
		uface.jump({
			mode: 'replace',
			path: weixinUrl
		})
	}
};
