const list = {
	wxlogin: 'Login/wxlogin', //验证登录
	bindPhone: 'Login/bindPhone', //绑定手机
	sendCode: 'Login/sendCode', //发送验证码
	getCompanyList: 'Login/getCompanyList', //获取公司列表
	login: 'Login/applogin', //app登录
	addUserCid: {
		url: 'Index/addUserCid',
		interceptor(interceptor) {
			interceptor.request.use(config => new Promise(resolve => {
				const cid = plus.push.getClientInfo().clientid;
				if (cid) {
					config.data.cid = cid;
					resolve(config);
				} else setTimeout(() => {
					config.data.cid = plus.push.getClientInfo().clientid;
					resolve(config);
				}, 3000)
			}))
		}
	}, // 添加用户cid
};

export default list;
