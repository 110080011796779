const list = {
// 权限
getLawLeftMenu:'Auth/getLawLeftMenu',//获取律所后台端左侧导航菜单列表
getNoButton:'Auth/getLawOperateAuthBySysId',//律师后台根据左侧菜单主键id查询当前页面没有权限的button

addUserAdminGroup:'Auth/addLawUserGroup',
    editUserAdminGroup:'Auth/editLawUserGroup',
    getLawUserGroupList:'Auth/getLawUserGroupList',
    getPermission:'Auth/getLawUserModuleList',
    getAuthDetail:'Auth/getLawUserGroupDetail',
    delAuth:'Auth/delLawUserGroup',
};

export default list;