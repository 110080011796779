
	 import store from '@/store';
	  export default function getTabBar(module){
		const currentModule=store.state.currentModule;
		module=module||currentModule;
		  
			  if(module==='txhg'){
				   return {
  "color": "#444444",
  "selectedColor": "#1980ff",
  "backgroundColor": "#ffffff",
  "list": [
    {
      "pagePath": "/pages/txhg/home/index",
      "iconPath": "/static/tabrBar/txhg/tab-1.png",
      "iconfont": "icon-shouye2-11",
      "selectedIconfont": "icon-shouye2",
      "selectedIconPath": "/static/tabrBar/txhg/tab-1-active.png",
      "text": "首页"
    },
    {
      "pagePath": "/pages/txhg/notice/index",
      "iconPath": "/static/tabrBar/txhg/tab-2.png",
      "iconfont": "icon-xiaoxi-11",
      "selectedIconfont": "icon-xiaoxi-12",
      "selectedIconPath": "/static/tabrBar/txhg/tab-2-active.png",
      "text": "消息"
    },
    {
      "pagePath": "/pages/txhg/applyLeave/type/index?title=申请",
      "iconPath": "/static/tabrBar/txhg/tab-3.png",
      "iconfont": "icon-shenqing2",
      "selectedIconfont": "icon-shenqing-1",
      "selectedIconPath": "/static/tabrBar/txhg/tab-3-active.png",
      "text": "申请"
    },
    {
      "pagePath": "/pages/txhg/application/index",
      "iconPath": "/static/tabrBar/txhg/tab-4.png",
      "iconfont": "icon-yingyong2",
      "selectedIconfont": "icon-yingyong-1",
      "selectedIconPath": "/static/tabrBar/txhg/tab-4-active.png",
      "text": "应用"
    },
    {
      "pagePath": "/pages/txhg/me/index",
      "iconPath": "/static/tabrBar/txhg/tab-5.png",
      "iconfont": "icon-wode1",
      "selectedIconfont": "icon-wode-1",
      "selectedIconPath": "/static/tabrBar/txhg/tab-5-active.png",
      "text": "我的"
    }
  ]
}
			  }
		
	  }