import jump from './core/uJump';
import showLoading from './core/uShowLoading';
import showToast from './core/uShowToast';
import showModal from './core/uShowModal.js';
// import defineModal from './core/uDefineModal.js';
import showLiveVideo from './core/uShowLiveVideo.js';
import uAPI from "./core/uAPI";
import wxShare from './core/wxShare.js';
import uLiveVideo from './core/uLiveVideo';
// 模拟函数重载
function addMethod(object, name, fn) {
	var old = object[name];
	object[name] = function(...rest) {
		if (fn.length === rest.length) {
			return fn.apply(this, rest);
		} else if (typeof old === "function") {
			return old.apply(this, rest);
		}
	};
}
const hideLoading=()=>uni.hideLoading();

/**
 * [uface description]    对于API的封装
 * @type {Object}
 */
const uface = {
	jump,
	showLoading,
	hideLoading,
	showToast,
	showModal,
	// defineModal,
	showLiveVideo,
	wxShare,
	LiveVideo:uLiveVideo
};

export default uface;
